.custom-btn {
    background-color: #334779;
    color: white;
    border-color: #334779;
  }
  
  .custom-btn:hover {
    background-color: #66759a;
    border-color: #334779;
    color: white;
  }
.soporte-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .soporte-soporte {
    width: 100%;
    height: 1108px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 1);
  }
  .soporte-rectangle14 {
    top: 1065px;
    left: -2px;
    width: 1159px;
    height: 43px;
    position: absolute;
  }
  .soporte-text {
    top: 1075px;
    left: 273px;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 20px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: 98.93858432769775%;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-rectangle1 {
    top: 2px;
    left: 0px;
    width: 1159px;
    height: 46px;
    position: absolute;
  }
  .soporte-rectangle2 {
    top: 2px;
    left: 922px;
    width: 179px;
    height: 46px;
    position: absolute;
  }
  .soporte-text02 {
    top: 9px;
    left: 949px;
    color: rgba(255, 255, 255, 1);
    width: 124px;
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-text04 {
    top: 9px;
    left: 669px;
    color: rgba(255, 255, 255, 1);
    width: 67px;
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-text06 {
    top: 9px;
    left: 775px;
    color: rgba(255, 255, 255, 1);
    width: 108px;
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-rectangle3 {
    top: 56px;
    left: 0px;
    width: 1159px;
    height: 106px;
    position: absolute;
  }
  .soporte-group1 {
    top: 63px;
    left: 403px;
    width: 342.99993896484375px;
    height: 87px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .soporte-image2 {
    top: 3px;
    left: 0px;
    width: 71px;
    height: 84px;
    position: absolute;
  }
  .soporte-text08 {
    left: 92.28565216064453px;
    color: rgba(255, 255, 255, 1);
    width: 251px;
    height: auto;
    position: absolute;
    font-size: 32px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-letter {
    top: 8px;
    left: 45px;
    width: 35px;
    height: 35px;
    position: absolute;
  }
  .soporte-text12 {
    top: 11px;
    left: 98px;
    color: rgba(255, 255, 255, 1);
    width: 283px;
    height: auto;
    position: absolute;
    font-size: 20px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-text14 {
    top: 180px;
    left: 301px;
    color: rgba(51, 71, 121, 1);
    width: 556px;
    height: auto;
    position: absolute;
    font-size: 64px;
    font-style: SemiBold;
    text-align: center;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: 98.93858432769775%;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-menu {
    top: 62px;
    left: 45px;
    width: 90px;
    height: 90px;
    position: absolute;
  }
  .soporte-line16 {
    top: 1041px;
    left: 577px;
    width: 768px;
    height: 1px;
    position: absolute;
  }
  .soporte-group6 {
    top: 329px;
    left: 71px;
    width: 424px;
    height: 638px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .soporte-text16 {
    left: 109px;
    color: rgba(51, 71, 121, 1);
    height: auto;
    position: absolute;
    font-size: 36px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: 98.93858432769775%;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-rectangle38 {
    top: 67px;
    left: 0px;
    width: 424px;
    height: 42px;
    position: absolute;
  }
  .soporte-rectangle39 {
    top: 122px;
    left: 0px;
    width: 424px;
    height: 42px;
    position: absolute;
  }
  .soporte-rectangle40 {
    top: 177px;
    left: 0px;
    width: 424px;
    height: 42px;
    position: absolute;
  }
  .soporte-rectangle41 {
    top: 232px;
    left: 0px;
    width: 424px;
    height: 342px;
    position: absolute;
  }
  .soporte-text18 {
    top: 76px;
    left: 14px;
    color: rgba(51, 71, 121, 0.699999988079071);
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: 98.93858432769775%;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-text20 {
    top: 131px;
    left: 14px;
    color: rgba(51, 71, 121, 0.699999988079071);
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: 98.93858432769775%;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-text22 {
    top: 186px;
    left: 14px;
    color: rgba(51, 71, 121, 0.699999988079071);
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: 98.93858432769775%;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-text24 {
    top: 244px;
    left: 14px;
    color: rgba(51, 71, 121, 0.699999988079071);
    width: 93px;
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: 98.93858432769775%;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-rectangle42 {
    top: 597px;
    left: 114px;
    width: 213px;
    height: 41px;
    position: absolute;
    border-radius: 91px;
  }
  .soporte-text26 {
    top: 605px;
    left: 174px;
    color: rgba(255, 255, 255, 1);
    width: 93px;
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: 98.93858432769775%;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-group5 {
    top: 329px;
    left: 658px;
    width: 430px;
    height: 491px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .soporte-text28 {
    left: 26px;
    color: rgba(51, 71, 121, 1);
    height: auto;
    position: absolute;
    font-size: 36px;
    font-style: SemiBold;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .soporte-text29 {
    color: rgba(51, 71, 121, 1);
  }
  .soporte-text31 {
    color: rgba(51, 71, 121, 1);
  }
  .soporte-text33 {
    color: rgba(51, 71, 121, 1);
  }
  .soporte-text35 {
    color: rgba(51, 71, 121, 1);
  }
  .soporte-text37 {
    color: rgba(51, 71, 121, 1);
  }
  .soporte-text39 {
    color: rgba(51, 71, 121, 1);
  }
  .soporte-text41 {
    color: rgba(51, 71, 121, 1);
  }
  .soporte-text43 {
    color: rgba(51, 71, 121, 1);
  }
  .soporte-text44 {
    color: rgba(236, 45, 52, 1);
  }
  .soporte-text45 {
    text-decoration: NONE;
  }
  .soporte-text47 {
    text-decoration: NONE;
  }
  .soporte-text49 {
    text-decoration: NONE;
  }
  .soporte-text51 {
    text-decoration: NONE;
  }
  .soporte-text52 {
    text-decoration: UNDERLINE;
  }
  .soporte-text54 {
    text-decoration: UNDERLINE;
  }
  .soporte-text55 {
    text-decoration: NONE;
  }
  .soporte-text56 {
    text-decoration: UNDERLINE;
  }
  .soporte-text57 {
    text-decoration: NONE;
  }
  .soporte-help {
    top: 173px;
    left: 0px;
    width: 20px;
    height: 20px;
    position: absolute;
  }
  .soporte-ok {
    top: 297px;
    left: 165px;
    width: 20px;
    height: 20px;
    position: absolute;
  }

.linea-vertical {
  width: 2px !important; /* Ancho de la línea vertical */
  background-color: #334779; /* Color de fondo */
  height: 450px; /* Altura fija de la línea vertical */
  padding: 0;
}
.form-group {
  margin-bottom: 40px; /* Dos líneas de espacio entre cada grupo de formulario */
}
.linea-vertical {
  width: 2px !important; /* Ancho de la línea vertical */
  background-color: #334779; /* Color de fondo */
  height: 100%; /* Altura igual a la altura del contenedor */
  padding: 0;
}

.info-right {
  margin-top: 50px; /* Ajusta según sea necesario */
}
.linea-azul {
  width: 0.5%;
  height: 500px; /* Aumentamos el grosor de la línea */
  background-color: blue;
  margin-top: 20px; /* Ajustamos la distancia desde arriba */
  margin-bottom: 20px; /* Ajustamos la distancia desde abajo */
  margin-right: 200px;
}

.divisor 
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1;
    border-top: 3px solid #334779;
}

.margen-enlaces
{
  margin-top: 50px;
}