.items-niveles
{
 margin-top: 20px !important;
}

.fuente
{
    font-family: "Playfair Display", serif;
}

.divisor 
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1;
    border-top: 3px solid #334779;
}

.items-desc
{
    color: #334779;
    font-family: "Playfair Display", serif;
    margin-top: 10px;
}

.titleinicial
{
    margin-top: 20px;
}

.divisor 
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1;
    border-top: 3px solid #334779;
}

.propuestas-text 
{
    font-family: "Playfair Display", serif;
    margin: 10px;
    color: #334779;
}

.imagenes-redondas
{
    height: 100px;
    width: auto;
}