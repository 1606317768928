.header {
  background-color: #334779;
  font-family: "Playfair Display", serif;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.long-text {
  flex-wrap: nowrap !important; /* Evita el salto de línea */
  white-space: nowrap !important; /* Evita el salto de línea */
}

.linkhide {
  text-decoration: none;
  color: black;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#columns-container {
  display: flex;
  justify-content: flex-end;
}

.headerlogo {
  flex-wrap: nowrap !important;
}

.linksheader {
  justify-content: flex-end !important;
}

.navbarprincipal {
  background-color: #334779 !important;
  color: white !important;
}

.isalogoheader {
  display: flex;
  width: 300px;
  height: auto;
  justify-content: center;
}

.textcolor {
  color: white;
  text-decoration: none;
}

.maillink {
  margin-left: 5px;
}

.left-section {
  display: flex;
  align-items: center;
}

.right-section {
  display: flex;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.email-link {
  color: white;
  text-decoration: none;
}

.link {
  color: white;
  text-decoration: none;
  margin-right: 20px;
}

.special-link {
  color: white;
  text-decoration: none;
  background-color: red;
  padding: 5px 10px;
  margin-right: 10px;
}

.bottom-section {
  background-color: white;
  padding: 20px;
}

.bottom-content {
  display: flex;
  align-items: center;
}

.bottom-image-center {
  width: 343px;
  height: 87px;
  margin: 0 auto;
  display: block;
}

.bi-list {
  width: 50px;
  height: 50px;
  fill: currentColor;
}