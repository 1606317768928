.carousel-caption-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.black-transparent {
    background-color: rgba(0, 0, 0, 0.5); /* Ajusta la opacidad aquí */
    height: 50px; /* Ajusta la altura de la tira negra aquí */
}