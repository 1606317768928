.carrusel {
  padding: 0;
  margin: 0;
  width: 100%;
}

.carousel-item img {
  width: 100%;
  height: 350px; /* Establece un tamaño fijo para todas las imágenes */
  object-fit: cover; /* Ajusta la imagen para cubrir todo el contenedor manteniendo la relación de aspecto */
}

.carousel-img {
  width: 100%;
  height: auto;
  max-height: 400px !important;
}

.azul {
  background-color: #334779;
}

.rojo {
  background-color: #EE343E;
}

.barracolores .col {
  background-color: #334779;
  height: 20px;
}

.barracolores .col:nth-child(even) {
  background-color: #EE343E;
}

.text-shadow {
  text-shadow: 2px 2px black;
}

.fondotransparente {
  background-color: black !important;
  opacity: 0.8 !important;
}

.divisor 
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1;
    border-top: 3px solid #334779;
}

.img-conv
{
  margin-bottom: 25px;
}

.widget-twt {
  height: 400px !important;
}